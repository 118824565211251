import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;
//import Foundation from 'foundation-sites';

import './lib/better-menu';

//import './lib/page-scroller';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();
import 'smoothscroll-anchor-polyfill';

import './lib/element-watcher';
import './lib/fancy-hide-show';
import './lib/chat-color-changer';
import './lib/chat-discussion-selector';

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';



//$(document).foundation();
