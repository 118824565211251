class ChatDiscussionSelector {

	constructor() {
		this.windowSelector = document.querySelector(".window-discussion");
		this.mirrorSelector = document.querySelector(".mirror-discussion");
		this.chat = document.querySelector("#comments");

		if(this.windowSelector) {
			this.run();
		}
	}

	run() {

		this.windowSelector.addEventListener("click", () => {
			const y = this.chat.getBoundingClientRect().top + window.pageYOffset + -150;
			window.scrollTo({top: y, behavior: 'smooth'});

			const radioWindow = document.querySelector("#custom_field_5f43fd5e5e29d-1_0_0");
			radioWindow.checked = true;

			const editor = document.querySelector(".ql-blank");

			editor.click();
			editor.focus();
		});

		this.mirrorSelector.addEventListener("click", () => {
			const y = this.chat.getBoundingClientRect().top + window.pageYOffset + -150;
			window.scrollTo({top: y, behavior: 'smooth'});

			const radioWindow = document.querySelector("#custom_field_5f43fd5e5e29d-2_0_0");
			radioWindow.checked = true;

			const editor = document.querySelector(".ql-blank");

			editor.click();
			editor.focus();
		});
	}
}


document.addEventListener("DOMContentLoaded", () => {
	new ChatDiscussionSelector();
})

export default ChatDiscussionSelector;