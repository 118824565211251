/**
 * Sort of a slider but not really
 **/

class FancyHideShow {
	constructor(element, options) {
		// Default options with spread overwrite for any custom options
		this.options = {
			duration: 1000,
			loop: true,
			...options
		};

		// Keep track of all the things~
		this.state = {
			animating: false,
			currentItem: 0
		};

		this.element = element;

		this.items = [];

		if (this.element.hasAttribute("data-hide-show")) {
			this.run();
		} else {
			console.error("FancyHideShow: this isn't setup correctly ");
		}
	}

	// Sets up the hide/show
	run() {
		this.items = this.element.querySelectorAll("[data-hs-item]");

		let iterator = 0;
		this.items.forEach(item => {
			item.setAttribute("data-hs-item", iterator);
			item.setAttribute("data-hs-is-active", "false");
			iterator++;
		});

		// Run itemSwitcher once to start the hide-show!
		this.itemSwitcher();

		setInterval(this.itemSwitcher.bind(this), this.options.duration);
	}

	itemSwitcher() {
		const prevItemIndex = this.previousItem();
		const nextItemIndex = this.nextItem();

		const nextItem = this.items[nextItemIndex];
		this.items[this.state.currentItem].setAttribute("data-hs-is-active", "false");
		nextItem.setAttribute("data-hs-is-active", "true");

		this.state.currentItem = nextItemIndex;
	}

	/**
	 * Returns the previous item in the hide-show item array
	 * @returns {*}
	 */
	previousItem() {
		const length = this.items.length;
		const previous = this.state.currentItem - 1;

		if (previous < 0) {
			return length - 1;
		} else {
			return previous;
		}
	}

	/**
	 * Returns the next item in the hide-show item array
	 * @returns {*}
	 */
	nextItem() {
		const length = this.items.length;
		const next = this.state.currentItem + 1;

		if (next >= length) {
			return 0;
		} else {
			return next;
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	const el = document.querySelector("[data-hide-show]");
	if (el) {
		new FancyHideShow(el, {
			duration: 6500
		});
	}
});
