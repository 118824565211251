
class ElementWatcher {

	constructor() {

		// The element that will be modified by the origin-element
		this.elementWatcher = document.querySelector("[data-element-watcher]");

		// The origin element is the one that will get its height calculated against scroll
		this.originElement = document.querySelector("[data-element-watcher-origin]");

		// This breaks the idea of the library but I ran out of time 🥺
		this.desktopMenuWatchers = document.querySelectorAll('#desktop-menu a');

		if(this.elementWatcher !== null && this.originElement !== null) {
			this.run();
		}
	}

	// Drop in any code snippet here and it will only ever run when the trigger is in the document
	run() {
		const originName = this.originElement.getAttribute("data-element-watcher-origin");

		if(originName) {
			this.elementWatcher.setAttribute('data-element-watcher-name', originName);
		}

		if(originName === "hero") {
			this.desktopMenuWatchers.forEach(menuItem => {
				// Run the scrollhandler once to make sure things are rendered right :)
				this.scrollHandler(menuItem);

				window.addEventListener("scroll", this.scrollHandler.bind(this, menuItem), {passive: true});
			})
		}

		// On initial Load lets run the scrollhandler once to make sure things are rendered correctly
		this.scrollHandler(this.elementWatcher);

		// Register main scroll Listener, scrollhandler needs a binded this and the element to watch
		window.addEventListener("scroll", this.scrollHandler.bind(this, this.elementWatcher), {passive: true});
	}

	scrollHandler(element) {
		const originRect = this.originElement.getBoundingClientRect();
		const originHeight = originRect.height;
		const originTop = Math.abs(originRect.top);

		const watcherRect = element.getBoundingClientRect();
		const watcherHeight = watcherRect.height;
		const watcherTop = watcherRect.top;


		const outOfBounds = originHeight - originTop - watcherHeight - watcherTop;
		if(outOfBounds < 0 && window.scrollY !== 0) {
			element.setAttribute('data-element-watcher-active', '');
		} else {
			element.removeAttribute('data-element-watcher-active');
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	new ElementWatcher();
})

export default ElementWatcher;