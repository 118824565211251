/**
 * BetterMenu™
 * Menus but with no strings attached.
 * 95% reliant on CSS because CSS is awesome but just enough JS to make life easy.
 *
 * @param breakpoint - standard size is set to 1024px switches between horizontal/vertical NO STYLING IS APPLIED by the js
 */
class BetterMenu {
	constructor(breakpoint = 1024) {
		// Grab all of the menus
		this.menus = document.querySelectorAll("nav[data-better-menu]");

		this.menuButton = document.querySelector("[data-bm-toggle]");
		// If a better menu is tagged responsive the class will switch based off of this value
		this.breakpoint = breakpoint;

		// Check to see if there are any menus
		if (this.menus !== null) {
			// Initialize all of the menus :)
			this.menus.forEach(menu => {
				const menuType = menu.getAttribute("data-better-menu");
				if (menuType === "vertical") {
					this.verticalMenuHandler(menu);
				} else if (menuType === "horizontal") {
					this.horizontalMenuHandler(menu);
				} else if (menuType === "responsive") {
					this.responsiveMenuHandler(menu);
				} else {
					console.error("Better Menu: Did you forget to give your Better Menu a type?");
				}
			});
		} else {
			console.log("No better menus on this page is everything okay?");
		}


		if (this.menuButton !== null) {
			// Keeping in the functionality of contextual handlers just in case we ever need more then 1 button
			this.menuButtonHandler(this.menuButton);
		}


	}

	/**
	 * Handles in context any menu that should have a vertical layout
	 * Will append the logic to handle the accordion transitions and a working arrow!
	 **/
	verticalMenuHandler(menu) {
		const dropdowns = menu.querySelectorAll(".menu-item-has-children");

		if (dropdowns.length >= 1) {
			// Parse through all dropdowns in the menu
			dropdowns.forEach(dropdown => {
				// Add the one attribute needed to the parent LI element
				dropdown.setAttribute("data-BM-dropdown-is-open", "false");
				dropdown.setAttribute("role", "none");
				const anchor = dropdown.querySelector("a");

				// registers a click event on the anchor and will toggle the dropdown attribute on the parent LI
				anchor.addEventListener("click", this.dropdownMenuHandler);
			});
		}
	}

	/**
	 * This doesn't do anything to the DOM functionality wise
	 * mainly to ensure the vertical menu doesn't leave dangling event listeners
	 *
	 * todo I'm not a huge fan of always forcing a remove eventListener on invoking the horizontalMenuHandler but necessary for the responsive functionality
	 * @param menu
	 */
	horizontalMenuHandler(menu) {
		// Silence is golden when everything happens in css :P
		const dropdowns = menu.querySelectorAll(".menu-item-has-children");

		if (dropdowns.length >= 1) {
			// Parse through all dropdowns in the menu
			dropdowns.forEach(dropdown => {
				const anchor = dropdown.querySelector("a");
				anchor.removeEventListener("click", this.dropdownMenuHandler);
			});
		}
	}


	menuButtonHandler(menuButton) {
		// Grab popup
		const popup = document.querySelector("[data-bm-popup]");
		const popupID = popup.getAttribute("id");
		const closeButton = document.querySelector("[data-bm-popup-close]");

		if(popupID === null) {
			console.error("Better Menu: `[data-bm-popup]` requires an ID for accessibility");
		}
		// give the popup and button some state :)
		popup.setAttribute("data-bm-popup-is-open", "false");
		menuButton.setAttribute("data-bm-popup-is-open", "false");

		// set aria labels
		menuButton.setAttribute('aria-expanded', 'false');
		menuButton.setAttribute('aria-controls', popupID);

		const popupToggler = (event) => {

			const popupState = popup.getAttribute("data-bm-popup-is-open");

			if (popupState === "true") {
				//lit lets close this B
				popup.setAttribute("data-bm-popup-is-open", "false");
				menuButton.setAttribute("data-bm-popup-is-open", "false");
			} else {
				// Open the menu!
				popup.setAttribute("data-bm-popup-is-open", "true");
				menuButton.setAttribute("data-bm-popup-is-open", "true");
			}
		};

		menuButton.addEventListener('click', popupToggler);
		closeButton.addEventListener('click', popupToggler);
	}

	/* Utility functions below */

	/**
	 * Handles the dropdown event on vertical dropdowns
	 *
	 * @param event
	 */
	dropdownMenuHandler(event) {
		const dropdownParent = event.target.parentNode;
		const dropdownIsOpen = dropdownParent.getAttribute("data-BM-dropdown-is-open");

		const dropdownAnchor = event.target;

		dropdownAnchor.setAttribute('aria-haspopup', 'true');
		dropdownAnchor.setAttribute('aria-expanded', "false");

		// Todo This whole evaulation section is beyond janky
		// Logic to define an area for the ::after/::before arrow to be clickable
		const anchorWidth = event.target.getBoundingClientRect().width;
		let anchorArrowWidthAfter = window.getComputedStyle(event.target, ":after").width.split("px")[0];
		let anchorArrowWidthBefore = window.getComputedStyle(event.target, ":before").width.split("px")[0];
		const anchorOffsetLeft = event.offsetX;
		let anchorArrowTrigger = false;

		if (anchorArrowWidthAfter === "0") {
			// This is a before arrow
			const trigger = anchorOffsetLeft + anchorWidth - anchorArrowWidthBefore - 15;
			if (trigger < anchorWidth) {
				anchorArrowTrigger = true;
			}
		} else if (anchorArrowWidthBefore) {
			// This is a after arrow
			const trigger = anchorWidth - anchorArrowWidthAfter - anchorOffsetLeft - 10;

			if (trigger > 0) {
				anchorArrowTrigger = true;
			}
		} else {
			throw Error("Better Menu: WOAH you can't touch both psuedo elements on a dropdown dummy");
		}
		// This whole evaulation section is SUPER jank


		// It's toggle time~
		if (dropdownIsOpen === "true") {
			// After elements are slightly jank but this will check to see if it was clicked
			if (anchorArrowTrigger) {
				event.preventDefault();
				dropdownParent.setAttribute("data-BM-dropdown-is-open", "false");
				dropdownAnchor.setAttribute('aria-expanded', "false");
			}
		} else {
			// prevent the anchor link from linking
			event.preventDefault();
			// Set the data attribute to true
			dropdownParent.setAttribute("data-BM-dropdown-is-open", "true");
			dropdownAnchor.setAttribute('aria-expanded', "true");
		}
	}


	/**
	 * Defines the data-better-menu attribute based on the breakpoint. Updates dynamically :D
	 * Useful for when you want to feel the pain of styling a single menu for desktop and mobile
	 *
	 * Todo be able to properly remove event listeners in a smart way
	 * @param menu
	 */
	responsiveMenuHandler(menu) {
		const responsiveMenuSwitcher = () => {
			const clientWidth = window.innerWidth;
			const menuType = menu.getAttribute("data-better-menu");
			let newMenuType = null;

			if (clientWidth >= this.breakpoint) {
				newMenuType = "horizontal";
			} else {
				newMenuType = "vertical";
			}

			if (!(menuType === newMenuType)) {
				menu.setAttribute("data-better-menu", newMenuType);

				if (newMenuType === "vertical") {
					this.verticalMenuHandler(menu);
				} else {
					this.horizontalMenuHandler(menu);
				}
			}
		};

		// Call the switcher once to situate the menu correctly
		responsiveMenuSwitcher();

		// Then throw the switcher in an event listener on window resizes
		window.addEventListener("resize", responsiveMenuSwitcher);
	}
}

//Todo get rid of this...
document.addEventListener("DOMContentLoaded", () => {
	let betterMenu = new BetterMenu();
});

export default BetterMenu;
