

class ChatColorChanger {

	constructor() {
		this.comments = document.querySelectorAll('.wpd-custom-field.wpd-cf-text');

		if(this.comments.length) {
			this.run();
		}
	}

	run() {
		this.comments.forEach(comment => {
			const commentType = comment.querySelector(".wpd-cf-value");
			const commentTypeValue = commentType.innerText;

			if(commentTypeValue === "Mirror") {
				commentType.style.backgroundColor = "#8DC63F";
			} else {
				commentType.style.backgroundColor = "#27AAE1";

			}
		})
	}
}


document.addEventListener("DOMContentLoaded", () => {
	new ChatColorChanger();
})

export default ChatColorChanger;